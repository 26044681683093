import { CopyrightIcon } from 'hugeicons-react'
import React from 'react'
import { Link } from 'react-router-dom'

const PageFooter = () => {
    const currenYear = new Date().getFullYear()
    return (
        <footer>
            <small><CopyrightIcon size={16} />{currenYear}, Akiira Properties,  all rights reserved</small>
        </footer>
    )
}

export default PageFooter
