import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/homePage";
import './css/main/main.css';
import GasogiLuxuryProperties from "./pages/gasogiLuxuryProperties";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gasogi-properties/" element={<GasogiLuxuryProperties />} />
      </Routes>
    </Router>
  );
}

export default App;
