import axios from "axios"
import { emailJsUserApiKey, emailJsServiceId, emailJsTemplateId } from "../constants/constans"

export const sendEmail = async (templateParameters, templateId) => {
    const emailsData = {
        service_id: emailJsServiceId,
        template_id: templateId,
        user_id: emailJsUserApiKey,
        template_params: templateParameters
    }
    try {
        const response = await axios.post('https://api.emailjs.com/api/v1.0/email/send', emailsData)
        console.log(response)
        if (response.status === 200) {
            return true
        }
    } catch (error) {
        console.error(error)
        return false
    }
}