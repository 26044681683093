
export const gasogiImages = [
    {
        id: "Exterior",
        category: "Private swimming pool for each unit: Serenity at your doorstep",
        description: "Dive into a world of relaxation and tranquility with our meticulously designed outdoor swimming pools. Whether you're starting your day with a refreshing swim or unwinding after a long day, our pool offers the perfect escape. This serene space is more than just a pool—it's your private retreat, designed to elevate your lifestyle and offer you the ultimate relaxation experience right at your doorstep.",
        images: [
            {
                image: "Gasogi 3D~ 20240117 13h31m40s.webp",
                name: "Outdoor View 1",
                link: "/images/gasogiProperty/outdoor/Gasogi 3D~ 20240117 13h31m40s.webp"
            },
            {
                image: "Gasogi 3D~ 20240117 14h49m50s.webp",
                name: "Outdoor View 2",
                link: "/images/gasogiProperty/outdoor/Gasogi 3D~ 20240117 14h49m50s.webp"
            },
            {
                image: "Gasogi 3D~ 20240117 14h50m08s.webp",
                name: "Outdoor View 3",
                link: "/images/gasogiProperty/outdoor/Gasogi 3D~ 20240117 14h50m08s.webp"
            },
            {
                image: "Gasogi 3D~ 20240118 08h40m25s.webp",
                name: "Outdoor View 4",
                link: "/images/gasogiProperty/outdoor/Gasogi 3D~ 20240118 08h40m28s.webp"
            },
            {
                image: "Gasogi 3D~ 20240118 08h54m24s.webp",
                name: "Outdoor View 5",
                link: "/images/gasogiProperty/outdoor/Gasogi 3D~ 20240118 08h54m24s.webp"
            },
            // {
            //     image: "Gasogi 3D~ 20240118 09h07m39s.webp",
            //     name: "Outdoor View 6",
            //     link: "/images/gasogiProperty/outdoor/Gasogi 3D~ 20240118 08h54m24s.webp"
            // },
            {
                image: "Gasogi 3D~ 20240118 09h07m33s.webp",
                name: "Outdoor View 7",
                link: "/images/gasogiProperty/outdoor/Gasogi 3D~ 20240118 09h07m33s.webp"
            }
        ]
    },
    {
        id: "Private home gym",
        category: "Your ultimate Wellness Escape",
        description: "Imagine starting your day in the comfort of your own private home gym on the second floor, with floor-to-ceiling windows that frame breathtaking panoramic views of Kigali’s skyline. As you exercise, feel the gentle breeze from the Kigali hills, creating a natural, refreshing environment that motivates and rejuvenates. \n\nMore than just a gym, this exclusive space reflects your commitment to a balanced, health-conscious lifestyle. Whether you're lifting weights, stretching, or meditating, the serene backdrop of Gasogi and the tranquil atmosphere set the tone for focused, inspired workouts. \n\nAfter an workout in your gym, immerse yourself in your own private swimming pool. The seamless transition from an energizing workout to a calming swim offers the perfect harmony of fitness and relaxation, right at your doorstep. Experience a lifestyle where health, luxury, and serenity come together, creating an unparalleled living experience at Akiira Estates Gasogi.",
        images: [
            {
                image: "Home Gym 2.webp",
                name: "Home Gym 2.webp",
                link: "/images/gasogiProperty/gym/Home Gym 2.webp"
            },
            {
                image: "Home Gym 3.webp",
                name: "Home Gym 3.webp",
                link: "/images/gasogiProperty/gym/Home Gym 3.webp"
            },
        ]
    },
    {
        id: "Expansive Living rooms",
        category: "Expansive Living rooms",
        description: "Experience the epitome of sophistication in our expansive living rooms. Designed as the heart of your home, these spaces are perfect for both intimate gatherings and grand entertaining. Bathed in natural light, thanks to floor-to-ceiling windows, our living rooms offer panoramic views of Kigali’s stunning landscape, creating a seamless connection between indoor and outdoor living. With high ceilings, bespoke finishes, and an open floor plan, each living room is a canvas of luxury, allowing you to personalize the space to reflect your unique style. Whether you're hosting guests or enjoying a quiet evening at home, our living rooms provide the perfect setting for every moment.",
        images: [
            {
                image: "Gasogi interior~ 20240117 16h15m39s.webp",
                name: "Living Room 1",
                link: "/images/gasogiProperty/interior/living-room/Gasogi interior~ 20240117 16h15m39s.webp"
            },
            {
                image: "Gasogi interior~ 20240117 16h15m55s.webp",
                name: "Living Room 2",
                link: "/images/gasogiProperty/interior/living-room/Gasogi interior~ 20240117 16h15m55s.webp"
            },
            {
                image: "Gasogi interior~ 20240117 16h20m22s.webp",
                name: "Living Room 3",
                link: "/images/gasogiProperty/interior/living-room/Gasogi interior~ 20240117 16h20m22s.webp"
            },
            {
                image: "Gasogi interior~ 20240118 16h20m38s.webp",
                name: "Living Room 4",
                link: "/images/gasogiProperty/interior/living-room/Gasogi interior~ 20240117 16h20m22s.webp"
            },
            {
                image: "Gasogi interior~ 20240118 16h21m03s.webp",
                name: "Living Room 5",
                link: "/images/gasogiProperty/interior/living-room/Gasogi interior~ 20240117 16h20m38s.webp"
            },
            {
                image: "Gasogi interior~ 20240118 16h21m03s.webp",
                name: "Living Room 5",
                link: "/images/gasogiProperty/interior/living-room/Gasogi interior~ 20240117 16h21m03s.webp"
            }
        ]
    },
    {
        id: "Bedroom",
        category: "Spacious Bedrooms: A perfect retreat",
        description: "Our spacious bedrooms are designed to be your personal sanctuary, a place where elegance meets comfort. Each room boasts large windows that flood the space with natural light, offering breathtaking views of Kigali's rolling hills. The sophisticated design, coupled with high-end finishes, ensures that your bedroom is not just a place to rest but a haven of peace and luxury. Every detail is crafted to provide you with an ambiance of warmth and comfort, making your bedroom the perfect retreat after a busy day.",
        images: [
            {
                image: "Gasogi interior~ 20240117 16h57m33s.webp",
                name: "Bedroom 1",
                link: "/images/gasogiProperty/interior/bedroom/Gasogi interior~ 20240117 16h57m33s.webp"
            },
            {
                image: "Gasogi interior~ 20240117 16h57m45s.webp",
                name: "Bedroom 2",
                link: "/images/gasogiProperty/interior/bedroom/Gasogi interior~ 20240117 16h57m37s.webp"
            },
            {
                image: "Gasogi interior~ 20240117 16h57m47s.webp",
                name: "Bedroom 3",
                link: "/images/gasogiProperty/interior/bedroom/Gasogi interior~ 20240117 16h57m41s.webp"
            },
            // {
            //     image: "Gasogi interior~ 20240118 09h34m41s.webp",
            //     name: "Bedroom 4",
            //     link: "/images/gasogiProperty/interior/bedroom/Gasogi interior~ 20240118 09h34m40s.webp"
            // },
            // {
            //     image: "Gasogi interior~ 20240118 09h34m51s.webp",
            //     name: "Bedroom 5",
            //     link: "/images/gasogiProperty/interior/bedroom/Gasogi interior~ 20240118 09h34m51s.webp"
            // },
            // {
            //     image: "Gasogi interior~ 20240118 09h35m05s.webp",
            //     name: "Bedroom 6",
            //     link: "/images/gasogiProperty/interior/bedroom/Gasogi interior~ 20240118 09h34m56s.webp"
            // },
            // {
            //     image: "Gasogi interior~ 20240118 09h35m05s.webp",
            //     name: "Bedroom 6",
            //     link: "/images/gasogiProperty/interior/bedroom/Gasogi interior~ 20240118 09h35m01s.webp"
            // }
        ]
    },
    {
        id: "Wardrobe",
        category: "Walk-in Closets: highlight your style",
        description: "Step into your dream closet every morning with our expansive walk-in closets. Thoughtfully designed with ample space for your entire wardrobe, these closets feature custom shelving, elegant finishes, and lighting that highlights your style. Whether you're dressing for the day or curating your outfit for a special evening, our walk-in closets offer an organized and luxurious space that complements your lifestyle.",
        images: [
            {
                image: "Gasogi interior~ 20240118 09h55m12s.webp",
                name: "Wardrobe 1",
                link: "/images/gasogiProperty/interior/wardrobe/Gasogi interior~ 20240118 09h55m12s.webp"
            },
            // {
            //     image: "Gasogi interior~ 20240118 09h55m20s.webp",
            //     name: "Wardrobe 2",
            //     link: "/images/gasogiProperty/interior/wardrobe/Gasogi interior~ 20240118 09h55m20s.webp"
            // },
            {
                image: "Gasogi interior~ 20240118 09h55m22s.webp",
                name: "Wardrobe 3",
                link: "/images/gasogiProperty/interior/wardrobe/Gasogi interior~ 20240118 09h55m20s.webp"
            },
            // {
            //     image: "Gasogi interior~ 20240118 10h01m10s.webp",
            //     name: "Wardrobe 4",
            //     link: "/images/gasogiProperty/interior/wardrobe/Gasogi interior~ 20240118 10h01m10s.webp"
            // },
            // {
            //     image: "Gasogi interior~ 20240118 10h01m15s.webp",
            //     name: "Wardrobe 5",
            //     link: "/images/gasogiProperty/interior/wardrobe/Gasogi interior~ 20240118 10h01m16s.webp"
            // },
            {
                image: "Gasogi interior~ 20240118 10h01m22s.webp",
                name: "Wardrobe 6",
                link: "/images/gasogiProperty/interior/wardrobe/Gasogi interior~ 20240118 10h01m22s.webp"
            },
            // {
            //     image: "Gasogi interior~ 20240118 10h01m25s.webp",
            //     name: "Wardrobe 7",
            //     link: "/images/gasogiProperty/interior/wardrobe/Gasogi interior~ 20240118 10h23m06s.webp"
            // },
            {
                image: "Gasogi interior~ 20240118 10h23m06s.webp",
                name: "Wardrobe 8",
                link: "/images/gasogiProperty/interior/wardrobe/Gasogi interior~ 20240118 10h23m06s.webp"
            },
            {
                image: "Gasogi interior~ 20240118 10h23m10s.webp",
                name: "Wardrobe 9",
                link: "/images/gasogiProperty/interior/wardrobe/Gasogi interior~ 20240118 10h23m10s.webp"
            }
        ]
    },
    {
        id: "Bathroom",
        category: "Bathrooms: a moment of indulgence and tranquility",
        description: "Indulge in the spa-like experience of our exquisitely designed bathrooms. Each bathroom is a masterpiece of modern design, featuring sleek, high-quality fixtures, spacious layouts, and luxurious materials with rain showers to provide you with the ultimate comfort and relaxation. The serene atmosphere of our bathrooms transforms your daily routine into a moment of indulgence and tranquility. ",
        images: [
            {
                image: "Gasogi interior~ 20240118 10h27m42s.webp",
                name: "Bathroom 1",
                link: "/images/gasogiProperty/interior/bathroom/Gasogi interior~ 20240118 10h27m42s.webp"
            },
            {
                image: "Gasogi interior~ 20240118 10h27m53s.webp",
                name: "Bathroom 2",
                link: "/images/gasogiProperty/interior/bathroom/Gasogi interior~ 20240118 10h27m53s.webp"
            },
            {
                image: "Gasogi interior~ 20240118 10h27m55s.webp",
                name: "Bathroom 3",
                link: "/images/gasogiProperty/interior/bathroom/Gasogi interior~ 20240118 10h27m57s.webp"
            },
            {
                image: "Gasogi interior~ 20240118 10h31m05s.webp",
                name: "Bathroom 4",
                link: "/images/gasogiProperty/interior/bathroom/Gasogi interior~ 20240118 10h31m05s.webp"
            },
            {
                image: "Gasogi interior~ 20240118 10h31m09s.webp",
                name: "Bathroom 5",
                link: "/images/gasogiProperty/interior/bathroom/Gasogi interior~ 20240118 10h31m09s.webp"
            },
            {
                image: "Gasogi interior~ 20240118 10h31m33s.webp",
                name: "Bathroom 6",
                link: "/images/gasogiProperty/interior/bathroom/Gasogi interior~ 20240118 10h31m13s.webp"
            }
        ]
    },
];