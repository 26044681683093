import React from 'react'

const LoadingSpinner = ({ size }) => {
    return (
        <div className='loadingSpinner' style={{ width: `${size || 16}px`, height: `${size || 16}px` }}>

        </div>
    )
}

export default LoadingSpinner
