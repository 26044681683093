import React, { useState } from 'react'
import PageHeader from '../components/pageHeader'
import '../css/homePage/homePage.css'
import { ArrowUpRight01Icon, Call02Icon, Facebook01Icon, InstagramIcon, Linkedin01Icon, Mail01Icon, TwitterSquareIcon } from 'hugeicons-react'
import { Link } from 'react-router-dom'
import PageFooter from '../components/pageFooter'
import { Helmet } from 'react-helmet'
import LoadingSpinner from '../components/loadingSpinner'
import axios from 'axios'
import { emailJsContactTemplateId, emailJsServiceId, emailJsTemplateId, emailJsUserApiKey, emailJsVisitTemplateId } from '../constants/constans'
import { sendEmail } from '../utils/emailJsService'
export const ContactForm = () => {
    const [formError, setFormError] = useState("")
    const [formSuccess, setFormSuccess] = useState("")

    const [isLoading, setIsLoading] = useState(false)
    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [message, setMessage] = useState("")
    const [showSuccessPopup, setShowSuccessPopup] = useState(false)

    const handleSubmit = async () => {
        setFormError("")
        setFormSuccess("")

        if (!email || !fullName || !phoneNumber || !message) {
            setFormError("All fields are required")
            setIsLoading(false)
            return
        }

        const templateParameters = {
            "from_name": fullName,
            "to_name": 'Akiira Properties',
            "message": message,
            "reply_to": email,
            "email": email,
            "phone_number": phoneNumber,
            'g-recaptcha-response': '03AHJ_ASjnLA214KSNKFJAK12sfKASfehbmfd...'
        }
        setIsLoading(true)
        if (await sendEmail(templateParameters, emailJsContactTemplateId)) {
            setShowSuccessPopup(true)
            setIsLoading(false)
        }
    }


    return (
        <>
            <form>
                <div className='field'>
                    <label htmlFor="fullName">Full Name:</label>
                    <input type="text" id="fullName" required onChange={(e) => setFullName(e.target.value)} placeholder='Enter your full name' />
                </div>
                <div className="half">
                    <div className='field'>
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" required onChange={(e) => setEmail(e.target.value)} placeholder='Enter your email' />
                    </div>
                    <div className='field'>
                        <label htmlFor="phoneNumber">Phone Number:</label>
                        <input type="tel" id="phoneNumber" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required onChange={(e) => setPhoneNumber(e.target.value)} placeholder='Phone number with country code' />
                    </div>
                </div>
                <div className='field'>
                    <label htmlFor="message">Message</label>
                    <textarea id="message" required onChange={(e) => setMessage(e.target.value)} placeholder='Enter your message' rows={5}></textarea>
                </div>
                {
                    formError
                    && <div className="form-errors">{formError}</div>
                }
                <button className='primary' onClick={handleSubmit} type="button">
                    {isLoading ? <><LoadingSpinner size={18} /> <span>Sending</span></> : "Send message"}
                </button>
            </form>
            {
                showSuccessPopup
                    ? <div className="popup thank-you">
                        <div className="content">
                            <h3>Message sent successfully!</h3>
                            <p>Thank you. We'll get back to you as soon as possible. If you have any questions or concerns, please don't hesitate to reach out.</p>
                            <Link onClick={() => setShowSuccessPopup(false)} className='button primary-button' to="/">Close</Link>
                        </div>
                    </div>
                    : ''
            }
        </>

    )
}

const BookSiteVisitPopup = ({ setShowPopup }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [visitBooked, setVisitBooked] = useState(false)
    const [formError, setFormError] = useState("")
    const [formSuccess, setFormSuccess] = useState("")

    // form fields

    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [date, setDate] = useState("")
    const [time, setTime] = useState("")

    // validation
    const handleBookSiteVisit = async () => {
        setFormError("")
        setFormSuccess("")
        if (!fullName || !phoneNumber || !email || !date || !time) {

            setFormError("All fields are required")
            return
        }

        const template_params = {
            "from_name": fullName,
            "email": email,
            "phone_number": phoneNumber,
            "date": date,
            "time": time,
            'g-recaptcha-response': '03AHJ_ASjnLA214KSNKFJAK12sfKASfehbmfd...'
        }

        setIsLoading(true)
        if (await sendEmail(template_params, emailJsVisitTemplateId)) {
            setIsLoading(false)
            setVisitBooked(true)
            setFormSuccess("You have successfully visited")
            setFullName("")
            setEmail("")
            setPhoneNumber("")
            setDate("")
            setTime("")
        }
        else {
            setIsLoading(false)
            setFormError("Failed to book visit, please try again later")
        }


    }
    return (<div className="popup">
        <div className="content">
            <h3>Book a site visit</h3>
            <p>We offer flexible visiting hours to accommodate your schedule and ensure you have the best experience possible.</p>
            <form className="book-a-visit-form">
                <div className='field'>
                    <label htmlFor="fullName">Full Name:</label>
                    <input value={fullName} onChange={(e) => setFullName(e.target.value)} type="text" id="fullName" required placeholder='Full name' />
                </div>
                <div className="half">
                    <div className='field'>
                        <label htmlFor="email">Email:</label>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="email" required placeholder='Your email' />
                    </div>
                    <div className='field'>
                        <label htmlFor="phoneNumber">Phone Number:</label>
                        <input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type="tel" id="phoneNumber" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required placeholder='Your phone number' />
                    </div>
                </div>
                <div className="half">
                    <div className='field'>
                        <label htmlFor="date">Date:</label>
                        <input value={date} onChange={(e) => setDate(e.target.value)} type="date" id="date" required />
                    </div>
                    <div className='field'>
                        <label htmlFor="time">Time:</label>
                        <input value={time} onChange={(e) => setTime(e.target.value)} type="time" id="time" required />
                    </div>
                </div>

            </form>
            {
                formError && <FormErrors error={formError} />
            }
            {
                formSuccess && <FormSuccess message="Site visit booked successfully!" />
            }

            <div className="buttons">
                <button onClick={() => setShowPopup(false)} className='secondary' to="/">Close</button>
                {
                    !visitBooked
                        ? <button disabled={isLoading} className='button primary' onClick={handleBookSiteVisit}>
                            {isLoading ? <><LoadingSpinner size={18} /> <span>Booking</span></> : "Book Site Visit"}
                        </button>
                        : ''

                }
            </div>
        </div>
    </div>
    )
}

const FormErrors = ({ error }) => {
    return (
        <div className="form-errors">
            <p>{error}</p>
        </div>
    )
}

const FormSuccess = ({ message }) => {
    return (
        <div className="form-success">
            <p>{message}</p>
        </div>
    )
}

const BuyHouseSteps = () => {
    const [stetIndex, setStetIndex] = useState(0)
    const handleStep = (index) => {
        setStetIndex(index)
    }
    const buyHouseSteps = [
        {
            "step": 1,
            "title": "Explore and Customize Your Future Home",
            "description": "Start your journey with Akiira Estates by exploring our premium selection of homes. Our expert consultants will work closely with you to understand your needs and preferences, ensuring you find the perfect home. Enjoy the freedom to customize some aspects of your property, from interior finishes to landscaping, creating a space that is uniquely yours."
        },
        {
            "step": 2,
            "title": "Secure Your Home with Confidence",
            "description": "Lock in your chosen home with a booking contract and a 10% deposit. This contract outlines every detail of your future residence, including the layout, materials, and location within the estate. You'll also receive a comprehensive payment schedule, a technical notice of construction specifics, and the projected delivery date, providing complete transparency and peace of mind."
        },
        {
            "step": 3,
            "title": "Finalize Your Investment",
            "description": "Three weeks after securing your home, finalize your purchase by signing the sales contract at the notary. This crucial step solidifies your choices and ensures that all agreed-upon terms and customizations are legally documented, paving the way for the construction phase."
        },
        {
            "step": 4,
            "title": "Stay Informed Throughout Construction",
            "description": "As we build your home, you will receive regular updates on the progress. Through detailed newsletters and scheduled site visits, you can monitor the construction process and see your dream home come to life. Our commitment to quality ensures that every aspect meets your expectations."
        },
        {
            "step": 5,
            "title": "Move In or Leverage Our Rental Services",
            "description": "Once your home is ready, take possession and start enjoying your new lifestyle. If you're looking to rent out your property, our professional rental management services can help you maximize your investment. Ownership transfer is completed upon final payment, marking the beginning of a new chapter in your life at Akiira Estates."
        },
    ]

    return (
        <div className="steps">
            {
                buyHouseSteps.map((step, index) => (

                    <div onClick={() => handleStep(index)} key={index} className={`step ${stetIndex === index ? 'open-step' : ''}`}>
                        <div className="content">
                            <div className="number">
                                <span>{index + 1}</span>
                            </div>
                            <h4 className="title"> {step.title}</h4>
                        </div>

                        <div className="details">
                            <p>{step.description}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

const WhyChooseAkkira = () => {
    const [stetIndex, setStetIndex] = useState(0)
    const handleStep = (index) => {
        setStetIndex(index)
    }
    const whyChooseAkkiraSteps = [
        {
            "title": "Cash Payment Plan",
            "description": "We offer a 5% discount for buyers who opt for full upfront payment."
        },
        {
            "title": "Installment Plan",
            "description": "For greater flexibility, buyers can choose our installment plan, with payments spread across key milestones: 10% at booking, 20% at month 2, 30% at month 4, 30% at month 6, and the final 10% at delivery."
        },
        {
            "title": "Personalized Experience",
            "description": "From selection to customization, our team is dedicated to making your home-buying experience smooth and enjoyable."
        },
        {
            "title": "Transparency and Support",
            "description": "Clear communication and regular updates to keep you informed every step of the way."
        },
        {
            "title": "Quality and Reliability",
            "description": "We pride ourselves on delivering high-quality homes on time, ensuring your satisfaction and peace of mind."
        },
        {
            "title": "One-Year Guarantee after-Sales Service",
            "description": "We provide a one-year post-delivery service to address any construction-related issues, ensuring our buyers have peace of mind."
        },
    ]

    return (
        <div className="steps">
            {
                whyChooseAkkiraSteps.map((step, index) => (

                    <div onClick={() => handleStep(index)} key={index} className={`step ${stetIndex === index ? 'open-step' : ''}`}>
                        <div className="content">
                            <div className="number">
                                <span>{index + 1}</span>
                            </div>
                            <h4 className="title"> {step.title}</h4>
                        </div>
                        <div className="details">
                            <p>{step.description}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
const HomePage = () => {
    const [showBookVisitPopup, setShowBookVisitPopup] = useState(false)

    const handleClick = (e, path) => {
        e.preventDefault()
        const targetId = path.split('#')[1];
        const targetSection = document.getElementById(targetId);

        if (targetSection) {
            const sectionPosition = targetSection.getBoundingClientRect().top + window.scrollY
            window.scrollTo({ top: sectionPosition - 100, behavior: 'smooth' });
        }

    }

    return (
        <div className='home-page'>
            <Helmet>
                <title>Welcome | Akkira Properties</title>
            </Helmet>
            <PageHeader />
            <section className='heroSection' id='welcome'>
                <div className="content">
                    <div className="container">
                        <h1>Welcome to Akiira Ventures</h1>
                        <p>At Akiira Ventures, we redefine luxury living in Rwanda. Our mission is to develop real estate that not only meets the highest standards of quality and design but does so at an accessible price. We believe that luxury should be within reach, and we are committed to delivering just that.</p>
                        <Link onClick={(e) => handleClick(e, '/#getInTouch')} className='button'>
                            <span>Get in touch</span>
                            <ArrowUpRight01Icon />
                        </Link>
                    </div>
                </div>
            </section>
            <section className='ourPhilosophy' id='ourPhilosophy'>
                <div className="container">
                    <div className="text">
                        <h2>Our Philosophy</h2>
                        <p>
                            Luxury, Design, Affordability and sustainability These are the pillars that guide every project at Akiira Ventures. We are dedicated to creating spaces that epitomize elegance and comfort while ensuring that these high-end living experiences remain affordable. Our developments are characterized by spacious spaces with breathtaking views, each complemented by a private swimming pool, fostering a serene and exclusive atmosphere.
                        </p>
                        <Link onClick={(e) => handleClick(e, '/#getInTouch')} className='button primary'>
                            <span>Get in touch</span>
                            <ArrowUpRight01Icon />
                        </Link>
                    </div>
                    <div className="img">
                        <img src="/images/home/philosophy.webp" alt="" />
                    </div>
                </div>
            </section>

            <section className='ourPromise section-highlighted'>
                <div className="container">
                    <div className="img">
                        <img src="/images/home/promise.webp" alt="" />
                    </div>
                    <div className="text">
                        <h2>Our Promise</h2>
                        <p>
                            With Akiira Ventures, you are not just purchasing a property; you are investing in a lifestyle. Our properties are meticulously designed to offer functionality and sophistication. From the layout of the rooms to the selection of materials, every detail is chosen to enhance the quality of life of our residents. Our commitment to affordability means we provide all these premium features at prices that make luxury living a reality for more people.
                        </p>
                        <Link onClick={(e) => handleClick(e, '/#getInTouch')} className='button primary'>
                            <span>Book a site visit</span>
                            <ArrowUpRight01Icon />
                        </Link>
                    </div>
                </div>
            </section>

            <section className='ourProperties' id='ourProperties'>
                <div className="container">
                    <div className="img">
                        <h2>Upcoming Properties</h2>
                        <img src="/images/home/Akiira Estates Gasogi.webp" alt="" />
                    </div>
                    <div className="text">
                        <h4 id=''>Akiira Estates Gasogi
                            Where Luxury Meets Comfort</h4>
                        <p>
                            Nestled on the scenic hills of Gasogi Kigali, Rwanda, Gasogi estates Development promises an unparalleled living experience. This exclusive project comprises six meticulously crafted units, each designed to harmonize with the natural landscape, offering breathtaking panoramic views. Every unit is a sanctuary of luxury, featuring spacious walk-in closets, private bathrooms, and expansive balconies. Our design philosophy focuses on maximizing natural light and ventilation while ensuring sustainability and environmental responsibility. Residents will enjoy private amenities such as serene swimming pools and beautifully landscaped green spaces, fostering a vibrant community atmosphere.
                        </p>
                        <Link to={"/gasogi-properties/"} className='button primary'>
                            <span>View more details</span>
                            <ArrowUpRight01Icon />
                        </Link>
                    </div>
                </div>
            </section>
            <section className="sustainability section-highlighted">
                <div className="container">
                    <div className="text">
                        <h2>Sustainability and Lifestyle</h2>
                        <p>
                            At Akiira Ventures, we seamlessly blend sustainability with luxury. All our units are designed with
                            eco-friendly materials and energy-efficient systems, offering a modern lifestyle that embraces green
                            living. From natural ventilation and solar power integration, every feature promotes wellness while
                            reducing your carbon footprint.
                        </p>
                        <Link onClick={(e) => handleClick(e, '/#siteMap')} className='button primary'>
                            <span>View site on  a map</span>
                            <ArrowUpRight01Icon />
                        </Link>
                    </div>
                    <div className="img">
                        <img src="/images/home/livestyle.webp" alt="" />
                    </div>
                </div>
            </section>
            <section className="cta">
                {
                    showBookVisitPopup
                        ?
                        <BookSiteVisitPopup setShowPopup={setShowBookVisitPopup} />
                        : ''
                }
                <div className="container">
                    <h1>
                        Discover Akira Estates at Gasogi– Your Oasis of Luxury and Sustainability in Kigali
                    </h1>
                    <Link onClick={() => setShowBookVisitPopup(true)} className='button'>
                        <span>Book a visit</span>
                        <ArrowUpRight01Icon />
                    </Link>
                </div>
            </section>
            <section className='howToBuy section-highlighted' id='howToBuy'>
                <div className="container">
                    <div className="img">
                        <img src="/images/home/how-to-buy.webp" alt="" />
                    </div>
                    <div className="text">
                        <h2 id=''>How to buy a home</h2>
                        <p>Your Path to Home Ownership at Akiira Estates, Gasogi</p>

                        <BuyHouseSteps />
                    </div>

                </div>
            </section>
            <section className='whyUs' id='whyUs'>
                <div className="container">
                    <div className="text">
                        <h2 id=''>Why Choose Akiira Estates?</h2>

                        <WhyChooseAkkira />
                    </div>
                    <div className="img">
                        <img src="/images/home/why.webp" alt="" />
                    </div>

                </div>
            </section>
            <section className='siteMap section-highlighted' id='siteMap'>
                <div className="container">
                    <h2>The site view on  a map</h2>
                    <iframe src='https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1061.7658966253052!2d30.18789906958038!3d-1.968775099875837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMcKwNTgnMDcuNiJTIDMwwrAxMScxOC44IkU!5e1!3m2!1sen!2srw!4v1723982440665!5m2!1sen!2srw" '></iframe>

                </div>
            </section>
            <section className='contactForm' id='getInTouch'>
                <div className="container">
                    <div className="text">
                        <h2>Get in touch</h2>
                        <p>
                            Scheduling a site visit is easy. Simply contact our team to arrange a convenient time. We offer flexible visiting hours to accommodate your schedule and ensure you have the best experience possible.
                        </p>
                        <div className="contact-form-links">
                            <div className="phone">
                                <Call02Icon size={24} />
                                <span>+250 788 282 727</span>
                            </div>

                            <div className="email">
                                <Mail01Icon size={24} />
                                <span>
                                    Estates@akiiraventures.com
                                </span>
                            </div>
                        </div>

                        <div className="social-media-links">
                            <Facebook01Icon size={32} />
                            <TwitterSquareIcon size={32} />
                            <InstagramIcon size={32} />
                            <Linkedin01Icon size={32} />
                        </div>
                    </div>
                    <div className="form">
                        <ContactForm />
                    </div>
                </div>
            </section>
            <PageFooter />
        </div>
    )
}

export default HomePage
