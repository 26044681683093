import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import GasogiPageHeader from '../components/gasogiPageHeader'
import { Link } from 'react-router-dom'
import { ArrowLeft01Icon, ArrowRight01Icon, ArrowUpRight01Icon, Call02Icon, Cancel01Icon, Facebook01Icon, InstagramIcon, Linkedin01Icon, Mail01Icon, TwitterSquareIcon } from 'hugeicons-react'
import { gasogiImages } from '../data/gasogiProperties'
import '../css/gasogiProperties/gasogiProperties.css'
import PageFooter from '../components/pageFooter'
import { ContactForm } from './homePage'

// this is for triggering deployment

const ImagesGallery = ({ images, index, setShowGallery }) => {
    console.log('Current index is: ', index)
    const [currentImageIndex, setCurrentImageIndex] = useState(index || 0);
    const currentImage = images[currentImageIndex];

    const handleNextImage = () => {
        setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }

    return (
        <div className="gallery-popup">
            <div className="property-image">
                {currentImage && (
                    <img src={currentImage.link} alt={currentImage.name} />
                )}

                <div className="buttons">
                    <button onClick={() => setShowGallery(false)}>
                        <Cancel01Icon />
                        <span>Close</span>
                    </button>
                    {
                        currentImageIndex + 1 <= images.length ?
                            <button onClick={handleNextImage}>
                                <span>Next</span>
                                <ArrowRight01Icon />
                            </button>
                            : <button onClick={() => setShowGallery(false)}>Close</button>
                    }
                </div>
            </div>
        </div>
    );
};


const GasogiLuxuryProperties = () => {
    const [gasogiProperties, setGasogiProperties] = useState([])
    const [propertyGallery, setPropertyGallery] = useState([])
    const [showGallery, setShowGallery] = useState(false)
    const [selectedImage, setSelectedImage] = useState()

    const showGalleryPopup = (images, index) => {
        setPropertyGallery(images)
        setSelectedImage(index)
        setShowGallery(!showGallery)
    }

    const handleClick = (e, path) => {
        e.preventDefault()
        const targetId = path.split('#')[1];
        const targetSection = document.getElementById(targetId);

        if (targetSection) {
            const sectionPosition = targetSection.getBoundingClientRect().top + window.scrollY
            window.scrollTo({ top: sectionPosition - 100, behavior: 'smooth' });
        }

    }

    useEffect(() => {
        setGasogiProperties(gasogiImages)
    })

    return (
        <div className='gasogi-properties'>
            {
                showGallery ?
                    <ImagesGallery images={propertyGallery} setShowGallery={setShowGallery} index={selectedImage} />
                    : ''
            }
            <Helmet>
                <title>AKIIRA ESTATES GASOGI – WHERE LUXURY MEETS COMFORT</title>
            </Helmet>
            <GasogiPageHeader />
            <section className='heroSection' id='welcome'>
                <div className="content">
                    <div className="container">
                        <h1>AKIIRA ESTATES GASOGI – WHERE LUXURY MEETS COMFORT</h1>
                    </div>
                </div>
            </section>

            {
                gasogiProperties.map((property, index) => (
                    <section id={property.id} key={index} className="gasogi-properties-grid">

                        <div className="container">
                            <div className="property-content"><h2>{property.category}</h2>
                                <h3 className="property-sub-title">{property.subTittle}</h3>
                                <p className='property-description' style={{ whiteSpace: 'pre-line' }}>{property.id === "Private home gym" ? highlightedDescription(property.description, ["private", "home", "gym", "floor-to-ceiling", "windows", "pool"]) : property.description}</p>
                            </div>
                            {
                                <div className="property-images">

                                    {property.images.map((image, index) => (
                                        <div onClick={() => showGalleryPopup(property.images, index)} key={index} className='image'>
                                            <img src={image.link} alt={image.name} />
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </section>
                ))
            }
            <section className='contactForm' id='getInTouch'>
                <div className="container">
                    <div className="text">
                        <h2>Get in touch</h2>
                        <p>
                            Scheduling a site visit is easy. Simply contact our team to arrange a convenient time. We offer flexible visiting hours to accommodate your schedule and ensure you have the best experience possible.
                        </p>
                        <div className="contact-form-links">
                            <div className="phone">
                                <Call02Icon size={24} />
                                <span>+250 788 282 727</span>
                            </div>

                            <div className="email">
                                <Mail01Icon size={24} />
                                <span>
                                    estates@akiiraventures.com
                                </span>
                            </div>
                        </div>

                        <div className="social-media-links">
                            <Facebook01Icon size={32} />
                            <TwitterSquareIcon size={32} />
                            <InstagramIcon size={32} />
                            <Linkedin01Icon size={32} />
                        </div>
                    </div>
                    <div className="form">
                        <ContactForm />
                    </div>
                </div>
            </section>
            <PageFooter />
        </div>
    )
}

export default GasogiLuxuryProperties

const highlightedDescription = (text, highlightWords) => {

    const highlightedText = text.split(' ').map((word, index) => {
        const shouldHighlight = highlightWords.includes(word.replace(/[.,]/g, ''));
        return shouldHighlight ? <strong key={index}>{word} </strong> : word + ' ';
    });
    return highlightedText;
}
