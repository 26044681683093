import { ArrowUpRight01Icon, Call02Icon, Cancel01Icon, Layers01Icon, Mail01Icon } from 'hugeicons-react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import '../css/pageHeader/pageHeader.css'

const GasogiPageHeader = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen)
    const navLinks = [
        { path: '/#welcome', label: 'Welcome' },
        { path: '/gasogi-properties/#Exterior', label: 'Exterior' },
        { path: '/gasogi-properties/#Expansive Living rooms', label: 'Living room' },
        { path: '/gasogi-properties/#Bedroom', label: 'Bedroom' },
        { path: '/gasogi-properties/#Wardrobe', label: 'Wardrobe' },
        { path: '/gasogi-properties/#Bathroom', label: 'Bathroom' },
    ]

    const handleClick = (e, path) => {
        e.preventDefault()
        const targetId = path.split('#')[1];
        const targetSection = document.getElementById(targetId);

        if (targetSection) {
            const sectionPosition = targetSection.getBoundingClientRect().top + window.scrollY
            window.scrollTo({ top: sectionPosition - 100, behavior: 'smooth' });
        }
        if (mobileMenuOpen) {
            setMobileMenuOpen(false)
        }
    }
    return (
        <header>
            <nav className='navigation'>
                <Link to={'/'} className='logo'>
                    <img src="/logo192.png" alt="" />
                </Link>
                <div onClick={toggleMobileMenu} className="menu-icon">
                    {
                        mobileMenuOpen ? <Cancel01Icon size={44} /> : <Layers01Icon size={44} />
                    }

                </div>
                <ul className={`links ${mobileMenuOpen ? 'open' : 'close'}`}>
                    {navLinks.map(link => (
                        <li onClick={(e) => handleClick(e, link.path)} className='link' key={link.path}>
                            <Link to={link.path}>{link.label}</Link>
                        </li>
                    ))}
                    <li className='nav-action'>
                        <Link onClick={(e) => handleClick(e, '/#getInTouch')} className='button'>
                            <span>Get in touch</span>
                            <ArrowUpRight01Icon />
                        </Link>
                    </li>

                    <div className="contact-links">
                        <Call02Icon size={32} />
                        <Mail01Icon size={32} />
                    </div>

                </ul>

            </nav>
        </header>
    )
}

export default GasogiPageHeader
